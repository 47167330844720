import { LaunchTypeInterface } from 'src/repository/interfaces/Launch/LaunchTypeInterface';
import { Launch } from 'src/repository/types/Launch';
import { api } from './_index';
import { injectable } from 'inversify';

@injectable()
export default class LaunchTypeApiService implements LaunchTypeInterface {
  /**
   * Display a listing of the resource.
   *
   */
  all(): Promise<Launch[]> {
    return api
      .get('/type/terms')
      .then((response) => response)
      .catch((error) => error.response);
  }
}
